.transition-container-2 {
    position: relative;
    width: 100%;
    height: 150px; /* Adjust height as necessary */
    margin-bottom: 200px;
}

.dashed-line-vertical-2a {
    border-right: 1px dashed #ccc;
    height: 100px;
    right: 20vw; /* Position the line on the right side */
    top: 0;
    position: absolute;
    width: 0;
}

.dashed-line-horizontal-2a {
    border-top: 1px dashed #ccc;
    width: 55.5%;
    right: 20vw;
    top: 100px;
    position: absolute;
}

.dashed-line-vertical-2b {
    border-right: 2px dashed #ccc;
    height: 100px;
    right: 75vw; /* Adjust the position to the right side */
    top: 100px;
    position: absolute;
    width: 0;
}

/* Arrow Styling */
.styled-arrow-2a {
    position: absolute;
    right: calc(73vw - ((1900px - 100vw) * 0.02)); /* Adjust dynamically based on screen width */
    top: 220px; /* Adjust top value to align with the vertical line */
    transform: rotate(90deg); /* Rotate the arrow to point left */
    height: 80px; /* Set the height of the arrow */
}
