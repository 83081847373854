.about-us-quality-content {
    margin: 0 auto;
    text-align: center;
    max-width: 920px;
    padding: 40px 20px;
    margin-top: 50px;
}

.about-us-quality-content h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 3rem; /* Increased font size */
    font-weight: 700;
    color: white;
    margin-bottom: 20px;
    line-height: 1.2;
}

.about-us-quality-content p {
    font-family: 'Raleway', sans-serif;
    font-size: 1.25rem; /* Increased font size */
    font-weight: 400;
    color: lightgray;
    line-height: 1.6;
    letter-spacing: 0.05em; /* Increased letter spacing */
    margin-bottom: 20px;
}

@media (max-width: 1000px) {
    .about-us-quality-content {
        margin-top: 0px;
    }

    .about-us-quality-content h1 {
        font-size: 2.5rem;
    }

    .about-us-quality-content p {
        font-size: 1rem;
    }
}
